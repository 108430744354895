<template>
    <v-row>
        <v-col cols="12" class="mt-2">

            <BrandGiftCard class="ml-1 mr-1 mb-2"
                            v-for="(row, i) in giftcard_datas"
                            :key="i"
                            :row="row"
                            @childs-event="showDialog"
            />

        </v-col>

        <v-dialog v-model="gift_dialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  style="z-index: 99"
        >
            <GiftDetailModal @hide="hideDialog" :item.sync="gift_detail_item">
            </GiftDetailModal>
        </v-dialog>

    </v-row>
</template>

<script>
    import axios from '../../../service/axios'

    export default {
        name: 'Favorite',
        components: {
            BrandGiftCard: () => import("@/components/card/BrandGiftCard"),
            GiftDetailModal: () => import("@/components/modal/GiftDetailModal"),
        },
        data(){
            return {
                brand_name:null,
                giftcard_datas:[],
                gift_dialog: false,
                gift_detail_item: null,
            }
        },
        mounted () {
            this.scroll()
        },
        created () {
            this.brand_name = this.$route.query.brand;
            // console.log('query brand',brand);
            this.listInfo(0);
        },
        methods: {
            showDialog(item){
                this.gift_detail_item = item;
                this.gift_dialog = true;
            },
            hideDialog(){
                this.gift_detail_item = null;
                this.gift_dialog = false;
            },
            initgiftcard(){
                this.giftcard_datas.splice(0, this.giftcard_datas.length);
                this.giftcard_datas = [];
            },
            async listInfo(next_key){
                if(next_key == 0){
                    this.initgiftcard()
                }

                const params = {
                    brand_name:this.brand_name,
                    next_key: next_key,
                };

                await axios.post('/api/brand/productList', params).then(res =>{
                    for(var gift of res.data.data){
                        this.giftcard_datas.push(gift);
                    }
                    this.isLoading = false;
                }).catch(error=>{
                    console.log('페스트 오류', error)
                    this.isLoading = false;
                });
            },
            scroll () {
                window.onscroll = () => {
                    var totalPageHeight = document.body.scrollHeight;
                    var scrollPoint = window.scrollY + window.innerHeight;
                    let off = totalPageHeight - scrollPoint;

                    // console.log(this.isLoading, scrollPoint, totalPageHeight, off)

                    // if(scrollPoint >= totalPageHeight || off <= 50) {
                    //     this.req_theme_data(this.next_token);
                    // }

                    if(this.isLoading == false && off <= 300) {
                        try{
                            var last_data = this.giftcard_datas[this.giftcard_datas.length - 1];
                            this.isLoading = true;
                            
                            // console.log(last_data, this.giftcard_datas);
                            var next_key = last_data['_id'];
                            console.log('next key :', next_key);
                            this.listInfo(next_key);
                        }catch(ex){
                            console.log(ex);
                        }
                    }
                }
            },
        },
    }

</script>

<style lang="scss">
    .preview_img{
        .v-image__image--cover {
            background-size: contain;
        }
    }
    .preview{
        .v-btn--icon.v-size--small{
            width: 10px;
            height: 10px;
        }
    }
    .subheader{
        font-size: 0.85rem;
        align-self: center;
    }
</style>